html, body, .App { 
    height: 100%;
    margin: 0;
    background-color: #F4F5F8;
}

/* phone and tablet portrait*/
@media only screen and (max-width: 900px) {
    html, body, .App {
        padding: 0;
    }
}